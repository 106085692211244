<template>
  <v-date-picker is-range v-model="state.range">
    <template v-slot="{ inputValue, inputEvents }">
      <div class="grid grid-2">
        <div class="form-group__input">
          <span>
            <label class="t-lbl">{{ $t("message.start_date") }}</label>
            <div class="clearfix"></div>
            <input
              class="bg-white form-input border br1 px-2 py-1"
              :value="inputValue.start"
              v-on="inputEvents.start"
            />
          </span>
          <span>
            <label class="t-lbl">{{ $t("message.end_date") }}</label>
            <div class="clearfix"></div>
            <input
              class="bg-white form-input border br2 px-2 py-1"
              :value="inputValue.end"
              v-on="inputEvents.end"
            />
          </span>
          <span>
            <label>&nbsp;</label>
            <button @click="filterByDates" class="form-btn ml-10">
              {{ $t("message.update") }}
            </button>
          </span>
        </div>
        <div
          class="f-r"
          v-if="
            state.routeName == 'Third' && Object.keys(state.journals).length
          "
        >
          <Dropdown
            @get="(val) => filterRowByCode(val.company_code)"
            select_label="Select Type"
            select_dd="client_dd"
            data-_field="company_code"
            defaultValue="false"
            :data="state.myCodes"
          ></Dropdown>
        </div>
      </div>
    </template>
  </v-date-picker>

  <div class="clearfix"></div>
  <br />
  <div class="journal--master" v-if="Object.keys(state.journals).length">
    <div class="journal-card m0">
      <div class="journal-card__header">
        <h3 class="journal-card__title">Balance</h3>
      </div>
      <br />
      <div class="grid d" style="--c: 2fr 1fr 0.5fr 0.5fr">
        <span class="t-lbl">Prohada 1.0.0</span>
        <span class="t-lbl"
          >Date de tirage: {{ new Date().toLocaleString() }}</span
        >
        <span class="t-lbl">Time: 17:26:00</span>
      </div>
      <br />
      <div class="journal-card__body">
        <div
          class="jRow main-head"
          style="--col: 1fr 1.75fr 1fr 1fr 1fr 1fr 1fr 1fr"
        >
          <span class="t-c">{{ $t("message.ohada_number") }}</span>
          <span class="t-c">{{ $t("message.libellè_ècriture") }}</span>
          <span class="t-r">bydebit</span>
          <span class="t-r">bycredit</span>
          <span class="t-r">Debit</span>
          <span class="t-r">Credit</span>
          <span class="t-r">{{ $t("message.mdebit") }}</span>
          <span class="t-r">{{ $t("message.mcredit") }}</span>
        </div>
      </div>

      <div class="jTable-loading" v-if="isLoading">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="jRow-body" v-if="!isLoading">
        <div
          v-for="(journal, index) in state.journals"
          :key="index"
          class="jRow"
          style="--col: 1fr 1.75fr 1fr 1fr 1fr 1fr 1fr 1fr"
        >
          <span class="jRow-col">{{ journal.account_number }}</span>
          <span class="jRow-col">{{ journal.designation }}</span>
          <span class="jRow-col t-r">{{ journal.byDebit }}</span>
          <span class="jRow-col t-r">{{ journal.byCredit }}</span>
          <span class="jRow-col t-r">{{ journal.totalDebit }}</span>
          <span class="jRow-col t-r">{{ journal.totalCredit }}</span>
          <span class="jRow-col t-r">{{ journal.mdebit }}</span>
          <span class="jRow-col t-r">{{ journal.mcredit }}</span>
        </div>
        <div class="jRow bt" style="--col: 2.8fr 1fr 1fr 1fr 1fr 1fr 1fr">
          <span class="jRow-col t-r">
            <strong> Total: </strong>
          </span>
          <span class="jRow-col t-r">
            <strong>
              <span class="t-r">{{
                state.totalBalance.before_year_total_debit
              }}</span>
            </strong>
          </span>
          <span class="jRow-col t-r">
            <strong>
              <span class="t-r">{{
                state.totalBalance.before_year_total_credit
              }}</span>
            </strong>
          </span>
          <span class="jRow-col t-r">
            <strong>
              <span class="t-r">{{ state.totalBalance.debit }}</span>
            </strong>
          </span>
          <span class="jRow-col t-r">
            <strong>{{ state.totalBalance.credit }}</strong>
          </span>
          <span class="jRow-col t-r">
            <strong>
              {{ state.totalBalance.mdebit }}
            </strong>
          </span>
          <span class="jRow-col t-r">
            <strong>
              {{ state.totalBalance.mcredit }}
            </strong>
          </span>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div>
        <button
          @click="generatePdfByFrontEnd"
          v-if="!pdf.generating"
          class="form-btn sm"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-file-text"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path
              d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"
            />
            <line x1="9" y1="9" x2="10" y2="9" />
            <line x1="9" y1="13" x2="15" y2="13" />
            <line x1="9" y1="17" x2="15" y2="17" />
          </svg>
          &nbsp;{{ $t("message.pdf") }}
        </button>
        <button class="form-btn disabled" disabled v-if="pdf.generating">
          Generating...
        </button>
      </div>
    </div>
  </div>
  <div
    v-if="!isLoading && !Object.keys(state.journals).length"
    class="journal--master main-lbl"
  >
    <div class="journal-card m0 second-lbl">
      <div class="journal-card__body">
        <span class="lbl-gray__empty">No Data Found</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "@vue/runtime-core";
import router from "@/router";
import { useStore } from "vuex";
export default {
  setup() {
    const isLoading = ref(1);
    const prevDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const state = reactive({
      journals: [],
      journalsObj: [],
      range: {
        start: prevDate,
        end: new Date(),
      },
      totalBalance: {
        debit: 0,
        credit: 0,
        // progressive_balance: 0,
        mdebit: 0,
        mcredit: 0,
        before_year_total_debit: 0,
        before_year_total_credit: 0,
      },
      periode_du: new Date(),
    });
    const pdf = ref({ generating: 0 });
    const store = useStore();
    const client_uuid = computed(() => router.currentRoute.value.params.uuid);
    const calculateTotalBalances = () => {
      state.totalBalance.debit = 0;
      state.totalBalance.credit = 0;
      state.totalBalance.mdebit = 0;
      state.totalBalance.mcredit = 0;
      state.totalBalance.before_year_total_debit = 0;
      state.totalBalance.before_year_total_credit = 0;

      state.journals.map((j) => {
        state.totalBalance.debit += j.totalDebit ? Number(j.totalDebit) : 0;
        state.totalBalance.credit += j.totalCredit ? Number(j.totalCredit) : 0;

        state.totalBalance.mdebit += j.mdebit ? Number(j.mdebit) : 0;
        state.totalBalance.mcredit += j.mcredit ? Number(j.mcredit) : 0;

        state.totalBalance.before_year_total_debit += j.byDebit
          ? Number(j.byDebit)
          : 0;
        state.totalBalance.before_year_total_credit += j.byCredit
          ? Number(j.byCredit)
          : 0;
      });
      // });
    };

    function filterByDates() {
      state.journals = [];
      state.journalsObj = [];
      fetchJournalAllEntries();
    }
    const fetchJournalAllEntries = async () => {
      isLoading.value = 1;
      await store
        .dispatch("journal/fetchAllEntries", {
          from_date: state.range.start,
          to_date: state.range.end,
          client_uuid: client_uuid.value,
        })
        .then((res) => {
          // for last year data merge all line into first line with same account number
          let lastYearData = res[1];
          Object.keys(lastYearData).map((obj) => {
            lastYearData[obj][0].totalCreditOfLastYear = 0;
            lastYearData[obj][0].totalDebitOfLastYear = 0;

            lastYearData[obj].map((item) => {
              lastYearData[obj][0].totalCreditOfLastYear =
                lastYearData[obj][0].totalCreditOfLastYear +
                Number(item.credit);
              lastYearData[obj][0].totalDebitOfLastYear =
                lastYearData[obj][0].totalDebitOfLastYear + Number(item.debit);
            });
            if (lastYearData[obj][0].type != "CARRY") {
              state.journalsObj.push(lastYearData[obj][0]);
            } else {
              if (lastYearData[obj][0].account_number.charAt(0) == "1") {
                state.journalsObj.push(lastYearData[obj][0]);
              }
            }
            // state.journalsObj.push(lastYearData[obj][0]);
          });

          // for current year data merge all line into first line with same account number
          let currentYearData = res[0];
          Object.keys(currentYearData).map((obj) => {
            currentYearData[obj][0].totalCredit = Number(0);
            currentYearData[obj][0].totalDebit = Number(0);

            currentYearData[obj].map((item) => {
              currentYearData[obj][0].totalCredit =
                currentYearData[obj][0].totalCredit + Number(item.credit);
              currentYearData[obj][0].totalDebit =
                currentYearData[obj][0].totalDebit + Number(item.debit);
            });
            if (currentYearData[obj][0].type != "CARRY") {
              state.journals.push(currentYearData[obj][0]);
            } else {
              if (currentYearData[obj][0].account_number.charAt(0) == "1") {
                state.journals.push(currentYearData[obj][0]);
              }
            }
          });
          // console.log("check journals ", state.journals);
          isLoading.value = 0;
          //for sorting by account number
          state.journals = state.journals.sort((a, b) =>
            a.account_number > b.account_number
              ? 1
              : b.account_number > a.account_number
              ? -1
              : 0
          );

          //append data that is match between current year data and last year data and calculation for third and first column
          state.journals.forEach((obj) => {
            state.journalsObj.forEach((obj2) => {
              if (obj.account_number == obj2.account_number) {
                obj.last_year_debit = obj2.totalDebitOfLastYear;
                obj.last_year_credit = obj2.totalCreditOfLastYear;
              }
            });
            if (obj.last_year_debit || obj.last_year_credit) {
              if (Number(obj.last_year_debit) > Number(obj.last_year_credit)) {
                obj.byDebit =
                  Number(obj.last_year_debit) - Number(obj.last_year_credit);
              } else {
                obj.byCredit =
                  Number(obj.last_year_credit) - Number(obj.last_year_debit);
              }
            }
            if (Number(obj.totalDebit) > Number(obj.totalCredit)) {
              obj.mdebit = Number(obj.totalDebit) - Number(obj.totalCredit);
            } else {
              obj.mcredit = Number(obj.totalCredit) - Number(obj.totalDebit);
            }
          });

          // calculation for last year data
          // state.journals.forEach((obj) => {
          //   if (obj.last_year_debit || obj.last_year_credit) {
          //     if (Number(obj.last_year_debit) > Number(obj.last_year_credit)) {
          //       obj.byDebit =
          //         Number(obj.last_year_debit) - Number(obj.last_year_credit);
          //     } else {
          //       obj.byCredit =
          //         Number(obj.last_year_credit) - Number(obj.last_year_debit);
          //     }
          //   }
          // });

          //calculation for current year data
          // state.journals.forEach((obj) => {
          //   if (Number(obj.totalDebit) > Number(obj.totalCredit)) {
          //     obj.mdebit = Number(obj.totalDebit) - Number(obj.totalCredit);
          //   } else {
          //     obj.mcredit = Number(obj.totalCredit) - Number(obj.totalDebit);
          //   }
          // });

          calculateTotalBalances();
        });
    };
    const generatePdfByFrontEnd = async () => {
      pdf.value.generating = 1;
      const pdfData = {
        operation_type: "balance",
        print_date: state.periode_du.toLocaleString(),
        frontend_data: state.journals,
        total_balance: state.totalBalance,
      };
      if (client_uuid.value !== undefined) {
        pdfData.client_id = state.client.id;
      }
      await store
        .dispatch("journal/generateFrontendPdf", pdfData)
        .then((res) => {
          pdf.value.generating = 0;
          if (res.status == "success") {
            let linkElement = document.createElement("a");
            linkElement.setAttribute("href", res.data);
            linkElement.setAttribute("target", "_blank");
            document.body.appendChild(linkElement);
            linkElement.click();
            document.body.removeChild(linkElement);
          }
        });
    };
    onMounted(async () => {
      if (!state.client)
        await store
          .dispatch("client/fetchClientByUUID", { uuid: client_uuid.value })
          .then((res) => {
            state.client = res.data;
          });
      store.commit("component/SET_LOADER", false);

      await fetchJournalAllEntries();
    });
    return {
      state,
      fetchJournalAllEntries,
      filterByDates,
      isLoading,
      calculateTotalBalances,
      pdf,
      generatePdfByFrontEnd,
    };
  },
};
</script>
